<template>
    <div id="analysis" style="margin-top: 10px;height: inherit;overflow: auto;">
        <div>
            <GridLayout :col-num="12"
                :style="{ position: 'relative', top: '-10px', overflowY: 'scroll', height: 'calc(100vh - 140px)' }"
                :is-draggable="draggable" :is-resizable="resizable" :layout="layout" :responsive="responsive"
                :row-height="60" :use-css-transforms="true" :vertical-compact="true">
                <GridItem v-for="(item, indexVar) in layout" :key="indexVar" :h="item.h" :i="item.i" :minW="item.minw"
                    :minH="item.minh" :static="item.static" :w="item.w" :x="item.x" :y="item.y" :isDraggable="false"
                    @resized="resized">
                    <div style="width: 100%; height: 100%; background: white">
                        <div style="width:100%" v-if="item.i == 1">
                            <bulletinBoard style="width:100%" :top="true" title="W01-需量分析" :whiteT="true">
                                <W0OneVue style="width:100%" :Height="W0OneHeight" :Width="W0OneWidth"></W0OneVue>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 2">
                            <bulletinBoard style="width:100%" :top="true" title="W02-购电分析" :whiteT="true">
                                <W0TwoVue style="width:100%" :Height="W0TwoHeight" :Width="W0TwoWidth"></W0TwoVue>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 3">
                            <bulletinBoard style="width:100%" :top="true" title="W03-报装分析" :whiteT="true">
                                <W0ThreeVue style="width:100%" :Height="W0ThreeHeight" :Width="W0ThreeWidth">
                                </W0ThreeVue>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 4">
                            <bulletinBoard style="width:100%" :top="true" title="W04-绿色消纳率" :whiteT="true">
                                <div style="display:flex;margin-bottom: 25px;">
                                    <div style="margin-right: 80px;">
                                        <div
                                            style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                            年总充总量</div>
                                        <div
                                            style="font-size: 24px;font-weight: 600;text-align: center;position: relative;left: -5px;color:#165DFF">
                                            1120.33 <span style="font-size: 14px; color: #000000;">Mwh</span></div>
                                    </div>
                                    <div style="margin-right: 80px;">
                                        <div
                                            style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                            年工总放电量</div>
                                        <div
                                            style="font-size: 24px;font-weight: 600;text-align: center;position: relative;left: -5px;color:#165DFF">
                                            798.23 <span style="font-size: 14px; color: #000000;">Mwh</span></div>
                                    </div>
                                    <div style="margin-right: 80px;">
                                        <div
                                            style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                            年工总放电量</div>
                                        <div
                                            style="font-size: 24px;font-weight: 600;text-align: center;position: relative;left: -5px;color:#165DFF">
                                            798.23 <span style="font-size: 14px; color: #000000;">Mwh</span></div>
                                    </div>
                                </div>
                                <W0FourVue style="width:100%" :Height="W0FourHeight" :Width="W0FourWidth"></W0FourVue>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 5">
                            <bulletinBoard style="width:100%" :top="true" title="W05-电率分析" :whiteT="true">
                                <W0FiveVue style="width:100%" :Height="W0FiveHeight" :Width="W0FiveWidth"></W0FiveVue>
                            </bulletinBoard>
                        </div>
                    </div>
                </GridItem>
            </GridLayout>
        </div>
    </div>
</template>
<script>
import bulletinBoard from "@/component/bulletinBoard";
import W0OneVue from "@/component/analysisCom/W0One";
import W0TwoVue from "@/component/analysisCom/W0Two";
import W0FourVue from "@/component/analysisCom/W0Four";
import W0FiveVue from "@/component/analysisCom/W0Five";
import W0ThreeVue from "@/component/analysisCom/W0Three";
import { GridLayout, GridItem } from 'vue-grid-layout'
export default {
    components: { bulletinBoard, GridItem, GridLayout, W0OneVue, W0TwoVue, W0FourVue, W0FiveVue, W0ThreeVue },
    data() {
        return {
            W0OneHeight: "300px",
            W0OneWidth: "600px",
            W0TwoHeight: "300px",
            W0TwoWidth: "600px",
            W0FourHeight: "270px",
            W0FourWidth: "600px",
            W0FiveHeight: "300px",
            W0FiveWidth: "600px",
            W0ThreeHeight: "300px",
            W0ThreeWidth: "600px",
            layout: [
                { x: 0, y: 2, w: 5, h: 6, i: 1, minw: 3, minh: 5 },
                { x: 5, y: 2, w: 5, h: 6, i: 2, minw: 3, minh: 5 },
                { x: 0, y: 8, w: 5, h: 6, i: 3, minw: 3, minh: 5 },
                { x: 5, y: 8, w: 5, h: 6, i: 4, minw: 3, minh: 5 },
                { x: 0, y: 14, w: 5, h: 6, i: 5, minw: 3, minh: 5 },
            ],
            responsive: true,
            draggable: true,
            resizable: true,
        };
    },
    methods: {
        resized(i, newH, newW, newHPx, newWPx) {
            console.log("RESIZE i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx)
            if (i == 1) {
                this.$nextTick().then(() => {
                    this.W0OneWidth = newWPx - 40 + 'px'
                    this.W0OneHeight = newHPx - 120 + 'px'
                })
            }
            if (i == 2) {
                this.$nextTick().then(() => {
                    this.W0TwoWidth = newWPx - 180 + 'px'
                    this.W0TwoHeight = newHPx - 120 + 'px'
                })
            }
            if (i == 3) {
                this.$nextTick().then(() => {
                    this.W0ThreeWidth = newWPx - 40 + 'px'
                    this.W0ThreeHeight = newHPx - 120 + 'px'
                })
            }
            if (i == 4) {
                this.$nextTick().then(() => {
                    this.W0FourWidth = newWPx - 180 + 'px'
                    this.W0FourHeight = newHPx - 160 + 'px'
                })
            }
            if (i == 5) {
                this.$nextTick().then(() => {
                    this.W0FiveWidth = newWPx - 40 + 'px'
                    this.W0FiveHeight = newHPx - 120 + 'px'
                })
            }
        },

    },
};
</script>
<style lang="less">

</style>