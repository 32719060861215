<template>
    <div ref="text_modle13" :style="{ width: Width, height: Height, position: 'relative' }" class="newspaper">
        <el-select v-model="value" clearable :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
            <el-option v-for="item in newspaperData" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
        <div ref="main_modle13" :style="{ width: Width, height: Height }"></div>
    </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
export default {
    name: 'detailsmask001',
    components: {
    },
    props: ['value', 'shuaxin', 'Height', 'Width'],
    data() {
        return {
            checked1: true,
            vm: null,
            dialogCategory: false,
            alarmWarningData1: [],
            alarmWarningData2: [],
            alarmWarningData3: [],
            newspaperData: [{
                value: "1",
                label: "1AT1_进线柜"
            }]
        }
    },

    methods: {
        initEchart() {
            this.vm = echarts.init(this.$refs.main_modle13, "macarons");
            var option13 = {
                backgroundColor: 'white',
                grid: {
                    left: '2%',
                    right: '2%',
                    bottom: '2%',
                    top: '5%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    axisLine: { show: false },
                  
                    axisTick: { show: false },
                    splitLine: { show: false },
                    data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', "11", "12"],
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    }
                },
                yAxis: {
                    type: 'value',
                    position: 'top',
                    max: 1000,
                    interval: 200,
                    splitLine: {
                        lineStyle: {
                            type: 'dashed',
                        }
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    }
                },
                visualMap: {
                    type:"piecewise",
                    show: false,
                    dimension: 1,
                    seriesIndex:0,
                    pieces: [
                        {
                            max:0,
                            color:"blue"
                        },{
                            min:600,
                            color:"red"
                        }
                    ],
                    outOfRange:{
                        color:"blue"
                    }
                },
                // 控住柱状图样式
                series: [
                    {
                        name: 'Electricity',
                        type: 'line',
                        smooth: true,
                        // prettier-ignore
                        data: [["1",300], ["2",400], ["3",250], ["4",260], ["5",270], ["6",300], ["7",550], ["8",500], ["9",400], ["10",390], ["11",380], ["12",790] ],
                        markArea: {
                            itemStyle: {
                                color: 'white'
                            },
                            Symbol:"none",
                            label:{
                                show:false,
                            },
                            data: [
                                [
                                    {
                                        // name: 'Morning Peak',
                                        yAxis: "600"
                                    },
                                    {
                                        yAxis: "800"
                                    }
                                ],
                            ]
                        }
                    }
                ]
            };

            this.vm.setOption(option13);
        }

    },
    watch: {
        Height(val, val2) {//titleDec即为父组件的值，val参数为值

            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        Width(val, val2) {//titleDec即为父组件的值，val参数为值
            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        value() {//titleDec即为父组件的值，val参数为值
            this.initEchart()
        },
        shuaxin() {//titleDec即为父组件的值，val参数为值
            this.vm.resize()
        },
    },
    mounted() {
        // this.getData()
        this.initEchart()
    }
}
</script>

<style scoped>
.des {
    position: absolute;
    top: calc(50% + 20px);
    left: calc(50% - 36px);
    z-index: 999;
}

.text17 {
    font-size: 17px;
    color: #202224;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 15px;
}

/deep/.des .el-icon-bottom:before {
    color: rgb(0, 182, 155);
}
</style>
<style lang="less">
.newspaper {
    .el-input__inner {
        width: 256px;
        height: 32px;
    }
    .el-input__icon{
        line-height: 32px;
    }
}
</style>