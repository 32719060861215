<template>
    <div ref="text_modle13" :style="{ width: Width, height: Height, position: 'relative' }" class="purchase">
        <el-select v-model="value" clearable :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
            <el-option v-for="item in purchaseData" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
        <div ref="main_modle13" :style="{ width: Width, height: Height }"></div>
        <div style="position: absolute; top: 30%; right: 20px;">
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; height: 12px; border-radius: 50%;  background:#CCD9FF; margin-right: 4px;"></span><span>用电量</span>
            </div>
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #FF7300; margin-right: 4px;"></span><span>需量电费</span>
            </div>
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #11A826; margin-right: 4px;"></span><span>容量电费</span>
            </div>
        </div>
    </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
export default {
    name: 'detailsmask001',
    components: {
    },
    props: ['value', 'shuaxin', 'Height', 'Width'],
    data() {
        return {
            checked1: true,
            vm: null,
            dialogCategory: false,
            alarmWarningData1: [],
            alarmWarningData2: [],
            alarmWarningData3: [],
            purchaseData: [{
                value: "1",
                label: "1AT1_进线柜"
            }]
        }
    },

    methods: {
        a() {
            console.log(this.checked1)
        },
        initEchart() {
            this.vm = echarts.init(this.$refs.main_modle13, "macarons");
            var option13 = {
                backgroundColor: 'white',
                grid: {
                    left: '2%',
                    right: '2%',
                    bottom: '2%',
                    top: '5%',
                    containLabel: true
                },
                xAxis: {
                    data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    }
                },
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(kWh)',
                        min: 0,
                        max: 1000,
                        interval: 200,
                        axisLabel: {
                            color: "rgba(134,144,156,1)" ,
                            fontSize:14,
                        }
                    },
                    {
                        type: 'value',
                        name: '元',
                        min: 0,
                        max: 2.000,
                        interval: 0.400,
                        axisLabel: {
                            color: "rgba(134,144,156,1)" ,
                            fontSize:14,
                        }
                    }
                ],
                // 控住柱状图样式
                series: [
                    {
                        data: [120, 200, 150, 80, 70, 110, 130, 40, 608, 409, 554, 446],
                        type: 'line',
                        areaStyle: {},
                        itemStyle: {
                            color: "#4580ff"
                        }
                    },
                    {
                        name: 'Email',
                        type: 'line',
                        itemStyle: {
                            color: "#FF7300"
                        },
                        data: [420, 632, 601, 534, 490, 330, 610]
                    },
                    {
                        name: 'Union Ads',
                        type: 'line',
                        itemStyle: {
                            color: "#11A826"
                        },
                        data: [500, 802, 791, 640, 560, 430, 710]
                    },
                ]
            };

            this.vm.setOption(option13);
        }

    },
    watch: {
        Height(val, val2) {//titleDec即为父组件的值，val参数为值

            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        Width(val, val2) {//titleDec即为父组件的值，val参数为值
            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        value() {//titleDec即为父组件的值，val参数为值
            this.initEchart()
        },
        shuaxin() {//titleDec即为父组件的值，val参数为值
            this.vm.resize()
        },
    },
    mounted() {
        // this.getData()
        this.initEchart()
    }
}
</script>

<style scoped>
.des {
    position: absolute;
    top: calc(50% + 20px);
    left: calc(50% - 36px);
    z-index: 999;
}

.text17 {
    font-size: 17px;
    color: #202224;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 15px;
}

/deep/.des .el-icon-bottom:before {
    color: rgb(0, 182, 155);
}
</style>
<style lang="less">
.purchase {
    .el-input__inner {
        width: 256px;
        height: 32px;
    }
    .el-input__icon{
        line-height: 32px;
    }
}
</style>