import { render, staticRenderFns } from "./analysis.vue?vue&type=template&id=2b52d423"
import script from "./analysis.vue?vue&type=script&lang=js"
export * from "./analysis.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports